/*
To get the headers for graphiql add /raw-user ot the end of your url
 */
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Modernizr from 'modernizr'
import Buefy from 'buefy'
import Vuelidate from 'vuelidate'
import Vue2Filters from 'vue2-filters'
import VueMq from 'vue-mq'
import vueDebounce from 'vue-debounce'
import Shave from 'shave'
import * as DOMPurify from 'dompurify'
import filters from './filters'
import axios from 'axios'
import VueAxios from 'vue-axios'
import apolloProvider from '@/graphql'
import VueDOMPurifyHTML from 'vue-dompurify-html'
// import auth from '../auth_config.json'
// import { Auth0Plugin } from './auth'
import { AppPermissionsPlugin } from './plugins/appPermissions'
// import { CurrentAppUserPlugin } from './plugins/currentAppUser'
import { GameplayPlugin } from './plugins/gamePlay'
import { FnbHelpersPlugin } from './plugins/fnbHelpers'
import { ColorsPlugin } from './plugins/colors'
import { ObserveVisibility } from 'vue-observe-visibility'
import Multiselect from 'vue-multiselect'
import PortalVue from 'portal-vue'
import 'vue-resize/dist/vue-resize.css'
import '@mdi/font/css/materialdesignicons.min.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VCalendar from 'v-calendar'
import * as dateFns from 'date-fns'
import Shepherd from 'shepherd.js'
import 'shepherd.js/dist/css/shepherd.css'
import TreeView from 'vue-json-tree-view'
import VueClipboard from 'vue-clipboard2'
import { spaceDasher } from '@/mixins/global'
// import VueGtag from 'vue-gtag'
// const { domain, clientId, audience } = auth

Vue.component('multiselect', Multiselect)
Vue.mixin(spaceDasher)
// Vue.use(VueGtag, {
//   config: { id: 'G-E3Z74WZ0M8' },
//   pageTrackerTemplate (to) {
//     return {
//       page_title: 'amazing page',
//       page_path: to.path
//     }
//   }
// })
Vue.use(VueClipboard)
Vue.use(VueDOMPurifyHTML)
Vue.use(Vuelidate)
Vue.use(TreeView)
Vue.use(vueDebounce)
Vue.use(PortalVue)
Vue.use(VCalendar, {
  componentPrefix: 'vc'
})
Vue.use(GameplayPlugin)
Vue.use(FnbHelpersPlugin)
Vue.use(AppPermissionsPlugin)
// Vue.use(CurrentAppUserPlugin)
Vue.use(ColorsPlugin)
Vue.directive('observe-visibility', ObserveVisibility)
Vue.use(VueAxios, axios)
Vue.use(Buefy, { defaultIconPack: 'mdi' })
Vue.use(Vue2Filters)
// Vue.use(Auth0Plugin, {
//   domain,
//   clientId,
//   audience,
//   onRedirectCallback: appState => {
//     router.push(
//       appState && appState.targetUrl
//         ? appState.targetUrl
//         : window.location.pathname
//     )
//   }
// })
Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    mobile: 768,
    tablet: 1025,
    desktop: 1217,
    widescreen: 1409,
    fullhd: Infinity
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})
Object.defineProperty(Vue.prototype, '$Shepherd', { value: Shepherd })
Object.defineProperty(Vue.prototype, '$DOMPurify', { value: DOMPurify })
Object.defineProperty(Vue.prototype, '$shave', { value: Shave })
Object.defineProperty(Vue.prototype, '$dateFns', { value: dateFns })
Object.defineProperty(Vue.prototype, '$modernizr', { value: Modernizr })
// register global filter from filters.js
for (const name in filters) {
  Vue.filter(name, filters[name])
}
Vue.config.productionTip = false

// eventHub to communicate events further than parent
Vue.prototype.$eventHub = new Vue()
Vue.config.productionTip = false

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
